<template>
    <v-container fluid class="down-top-padding py-0">
        <BaseBreadcrumb class="hidden-sm-and-down"
                :title="page.title"
                :icon="page.icon"
                :breadcrumbs="breadcrumbs">
        </BaseBreadcrumb>
        <v-row>
            <v-col cols="12" class="py-0">
                <TheColorSalesCards></TheColorSalesCards>
            </v-col>
            <v-col cols="12" sm="12" lg="3" class="d-flex align-stretch">
                <div class="w-100">
                    <TheFollowerCardA></TheFollowerCardA>
                    <TheFollowerCardB></TheFollowerCardB>
                </div>
            </v-col>
            <v-col cols="12" sm="12" lg="9">
                <TheSalesOverview></TheSalesOverview>
            </v-col>
            <v-col cols="12" sm="12" lg="6">
                <TheMyContacts></TheMyContacts>
            </v-col>
            <v-col cols="12" sm="12" lg="6">
                <TheRecentComments></TheRecentComments>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import BaseBreadcrumb from "@/components/dashboards/BaseBreadcrumb";
    
    export default {
        name: "Statistics",
        title: "Dashboard Estadístico | Turismo BC",
        data: () => ({
            page: {
                title: "Turismo BC",
            },
            breadcrumbs: [
                {
                    text: "Dashboard",
                    disabled: true,
                    to: "/dashboards",
                },
                {
                    text: "Estadísticas",
                    disabled: true,
                },
            ],
        }),
        components: {
            BaseBreadcrumb,
            TheColorSalesCards: () =>
                import(
                    "@/components/dashboards/color-sales-cards/TheColorSalesCards"
                    ),
            TheFollowerCardA: () =>
                import(
                    "@/components/dashboards/follower-card/TheFollowerCardA"
                    ),
            TheFollowerCardB: () =>
                import(
                    "@/components/dashboards/follower-card/TheFollowerCardB"
                    ),
            TheSalesOverview: () =>
                import(
                    "@/components/dashboards/website-visit/SiteVisitOverview"
                    ),
            TheMyContacts: () =>
                import(
                    "@/components/dashboards/my-contacts/TheMyContacts"
                    ),
            TheRecentComments: () =>
                import(
                    "@/components/dashboards/recent-comments/TheRecentComments"
                    ),
        },
    };
</script>